import { FC, PropsWithChildren, ReactNode } from 'react'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import { styled } from 'styled-components'

import Icon from 'core/components/Icon'
import HelpText from 'core/components/lib/HelpText'
import { variables } from 'core/styles'

type DialogProps = {
  padding?: string
  width?: 'small' | 'medium'
  heightContainer?: 'small' | 'medium' | 'large'
  title?: ReactNode
  helpText?: ReactNode
  content: ReactNode
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
}

const Dialog: FC<PropsWithChildren<DialogProps>> = ({
  children,
  padding = '24px',
  width = 'small',
  heightContainer,
  title,
  helpText,
  content,
  isOpen,
  onOpenChange,
}) => (
  <DialogPrimitive.Root onOpenChange={onOpenChange} open={isOpen}>
    <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>
    <DialogPrimitive.Portal>
      <Overlay>
        <Content
          $maxHeight={
            heightContainer === 'small' ? '40vh'
            : heightContainer === 'medium' ?
              '65vh'
            : heightContainer === 'large' ?
              '90vh'
            : undefined
          }
          $overflowY={heightContainer ? 'auto' : undefined}
          $padding={padding}
          $width={width === 'small' ? '480px' : '680px'}
        >
          <CloseButton>
            <Icon color={variables.colorWhite} fontSize='28px' name='clear' />
          </CloseButton>
          {!!title && (
            <Header>
              <HelpText helpTextMargin='16px 24px 0' iconMargin='0 16px 0 0' text={helpText}>
                <Title margin={padding}>{title}</Title>
              </HelpText>
            </Header>
          )}
          <Body margin={padding}>{content}</Body>
        </Content>
      </Overlay>
    </DialogPrimitive.Portal>
  </DialogPrimitive.Root>
)

export default Dialog

const Overlay = styled(DialogPrimitive.Overlay)`
  display: grid;
  position: fixed;
  inset: 0;
  place-items: center;
  z-index: 10;
  background: ${variables.overlayDark};
  padding: 64px 8px;
  overflow-y: auto;
`

const CloseButton = styled(DialogPrimitive.Close)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(32px, -32px);
  outline-offset: 2px;
  border: none;
  border-radius: 28px;
  background: none;
  cursor: pointer;
`

const Content = styled(DialogPrimitive.Content)<{
  $width: string
  $maxHeight?: string
  $padding: string
  $overflowY?: string
}>`
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  background: ${variables.colorWhite};
  padding: ${(p) => p.$padding};
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 100%;
  max-width: ${(p) => p.$width};
  ${({ $maxHeight }) => ($maxHeight ? `max-height: ${$maxHeight};` : '')}
  ${({ $overflowY }) => ($overflowY ? ` overflow-y: ${$overflowY};` : '')}
`

const Body = styled.div<{ margin: string }>`
  margin: ${(p) => p.margin};
  margin-top: 0;
`

const Header = styled.header`
  margin-bottom: 16px;
`

const Title = styled(DialogPrimitive.Title)<{ margin: string }>`
  margin: ${(p) => p.margin};
  margin-top: 0;
  margin-bottom: 0;
  color: ${variables.colorBlack90};
  font-size: 2.2rem;
`

export const DialogText = styled.p`
  margin: 0;
  color: ${variables.colorBlack60};

  & + & {
    margin-top: 8px;
  }
`

export const DialogActions = styled.div`
  display: flex;
  gap: 32px;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 32px 0 0;
`
