import { FC } from 'react'

import { css, styled } from 'styled-components'

import { Field } from 'core/hooks/useForm'
import { variables } from 'core/styles'

type TextareaProps = Field<string> & {
  className?: string
  /** The starting number of visible text lines. */
  rows?: number
  /** The maximum number of visible text lines. */
  maxRows?: number
  /** Clamps the text length. When set shows a character counter. */
  maxLength?: number
  /** When true the control grabs focus as soon as it's rendered. */
  autoFocus?: boolean
  /** A hint to the user of what can be entered in the control. */
  placeholder?: string
}

/**
 * A `textarea`, with an optional character coutner, that grows to fit content.
 */
const Textarea: FC<TextareaProps> = ({
  className,
  disabled,
  maxLength,
  maxRows,
  name,
  onChange,
  rows = 3,
  value,
  ...props
}) => (
  <Box $maxRows={maxRows} $rows={rows} className={className}>
    <StyledTextarea
      disabled={disabled}
      maxLength={maxLength}
      name={name}
      onChange={(e) => onChange?.(e.target.value)}
      value={value}
      {...props}
    />

    <GrowBox>{value}&#8203;</GrowBox>
    {!!maxLength && value && value.length > 0 && (
      <Counter>
        {value.length}/{maxLength}
      </Counter>
    )}
  </Box>
)

export default Textarea

export const Box = styled.div<{ $rows: number; $maxRows?: number }>`
  position: relative;
  width: 100%;
  min-height: ${(p) => p.$rows * 24 + 18}px;
  ${({ $rows, $maxRows }) => (!!$maxRows && $maxRows >= $rows ? `max-height: ${$maxRows * 24 + 18}px;` : '')}
  line-height: 1.5;
  font-size: 16px;
`

export const TextAreaCSS = css`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid ${variables.colorBlack20};
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  height: 100%;
  resize: none;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;

  &:hover {
    border-color: ${variables.colorBlack30};
  }

  &:focus {
    outline: none;
    border: 1px solid ${variables.colorBlueSecondary};
  }

  &:disabled {
    border-color: #e6e6e6;
    background-color: #f2f2f2;
    color: #999;
  }

  ::placeholder {
    color: ${variables.colorBlack50};
  }
`

const StyledTextarea = styled.textarea`
  ${TextAreaCSS}
`

const GrowBox = styled.div`
  visibility: hidden;
  border: 1px solid transparent;
  padding: 8px;
  white-space: pre-wrap;
  font-family: inherit;
`

const Counter = styled.div`
  position: absolute;
  right: 8px;
  bottom: -11px;
  z-index: 1;
  background-color: white;
  padding: 0 2px;
  color: ${variables.colorBlack60};
  font-size: 14px;
`
