import { ArrayElement } from 'core/types'

import AmexIcon from './internal/custom/AmexIcon'
import DiscoverIcon from './internal/custom/DiscoverIcon'
import MastercardIcon from './internal/custom/MastercardIcon'
import SparklesIcon from './internal/custom/SparklesIcon'
import VisaIcon from './internal/custom/VisaIcon'

/**
 * If adding an icon available in the Material UI icons library add the exact
 * name of the icon as shown in the library:
 * https://material.io/resources/icons/?style=round to the `standardIcons`
 * array (Note that it uses snake_case for its naming, e.g. `3d_rotation`.)
 *
 * If making a custom icon, wrap with SvgIcon and store in ./internal, then
 * import here and add to `customIcons`.
 *
 * Keep the list alphabetized.
 */

export const customIcons = {
  americanExpress: AmexIcon,
  discover: DiscoverIcon,
  mastercard: MastercardIcon,
  sparkles: SparklesIcon,
  visa: VisaIcon,
}

export const standardIcons = [
  'ac_unit',
  'account_balance',
  'account_circle',
  'add',
  'add_circle', // identical to mui's control_point icon
  'add_ic_call',
  'alarm',
  'alarm_add',
  'alternate_email',
  'announcement',
  'arrow_back',
  'arrow_back_ios', // chevron that is bigger by filling the space more than keyboard_arrow_left
  'arrow_drop_down',
  'arrow_drop_up',
  'arrow_forward_ios', // chevron that is bigger by filling the space more than keyboard_arrow_right
  'arrow_upward',
  'article',
  'assignment',
  'attach_file',
  'block',
  'bolt',
  'business',
  'cached',
  'calendar_today',
  'call_end',
  'call_made',
  'camera_alt',
  'cancel',
  'check',
  'check_box',
  'check_circle',
  'clear', // identical to mui's close icon
  'close',
  'close_fullscreen',
  'content_copy',
  'create',
  'credit_card',
  'data_object',
  'date_range',
  'delete_forever',
  'description',
  'dns',
  'download',
  'edit',
  'email', // identical to mui's mail icon
  'emergency_home',
  'event', // identical to mui's insert_invitation icon
  'exit_to_app',
  'expand',
  'face',
  'fast_forward',
  'fiber_manual_record',
  'filter_list',
  'flag',
  'format_ink_highlighter',
  'gavel',
  'gpp_good',
  'help',
  'highlight_off',
  'history',
  'home',
  'hourglass_empty',
  'how_to_vote',
  'image_not_supported',
  'info',
  'keyboard_arrow_down', // identical to mui's expand_more icon
  'keyboard_arrow_left', // identical to mui's chevron_left icon
  'keyboard_arrow_right', // identical to mui's chevron_right icon
  'keyboard_arrow_up', // identical to mui's expand_less icon
  'launch',
  'link',
  'lock',
  'lock_open',
  'mail',
  'markunread_mailbox',
  'match_case',
  'match_word',
  'maximize',
  'mic',
  'mic_off',
  'minimize',
  'mobile_screen_share',
  'monetization_on',
  'more_vert',
  'north_east',
  'notification_important',
  'open_in_full',
  'open_in_new',
  'pause',
  'pause_circle',
  'payments',
  'people',
  'person',
  'phone', // identical to mui's call icon
  'phone_callback',
  'phone_in_talk',
  'phone_missed',
  'phone_paused',
  'play_arrow',
  'portrait',
  'preview',
  'print',
  'priority_high',
  'push_pin',
  'question_answer',
  'redo',
  'reduce',
  'refresh',
  'remove',
  'remove_circle',
  'replay',
  'reply',
  'reply_all',
  'report',
  'report_gmailerrorred',
  'request_page',
  'rotate_left',
  'rotate_right',
  'schedule', // identical to mui's query_builder, also what you'd probably call "clock"
  'screen_share',
  'search',
  'settings',
  'smartphone',
  'snooze',
  'star', // identical to mui's star_rate icon
  'supervisor_account',
  'sync',
  'text_format',
  'text_snippet',
  'thumb_down',
  'thumb_up',
  'thumbnail_bar',
  'trending_down',
  'undo',
  'unfold_less',
  'unfold_more',
  'upload',
  'vertical_align_bottom',
  'visibility',
  'visibility_off',
  'voicemail',
  'warning',
  'waves',
  'work',
] as const

export type IconName = keyof typeof customIcons | ArrayElement<typeof standardIcons>
