/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as InteractionTypes from '../interactions/types'
import * as SchemaTypes from '../schema/types'

import * as CaseTypes from './types'

export type ListCaseTypeKey = ['case-types', unknown]

export type ListCaseTypeResponse = CaseTypes.ResponseCaseTypesPaging

export type ListCaseTypeVariables = {
  body?: void
  query?: {
    loanTypeId?: Array<SchemaTypes.AnyId>
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    smartReviewsEnable?: boolean
  }
}

export const listCaseType = makeData<ListCaseTypeResponse, ListCaseTypeKey, ListCaseTypeVariables>({
  method: 'get',
  name: 'listCaseType',
  summary: 'Get case types',
  path: '/case-types',
  queryKey: ({ query }) => ['case-types', query],
})

export type CreateCaseTypeKey = ['case-types', unknown]

export type CreateCaseTypeResponse = CaseTypes.ResponseCaseType

export type CreateCaseTypeVariables = {
  body?: CaseTypes.RequestBodyCaseTypePost
  query?: void
}

export const createCaseType = makeData<CreateCaseTypeResponse, CreateCaseTypeKey, CreateCaseTypeVariables>({
  method: 'post',
  name: 'createCaseType',
  summary: 'Create type',
  path: '/case-types',
  queryKey: ({ query }) => ['case-types', query],
})

export type InternalListCaseTypesKey = ['companies', string | undefined, 'case-types', unknown]

export type InternalListCaseTypesResponse = CaseTypes.ResponseCaseTypesPaging

export type InternalListCaseTypesVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    loanTypeId?: Array<SchemaTypes.AnyId>
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    caseType?:
      | 'generic'
      | 'bankruptcy'
      | 'ceaseCommunication'
      | 'collection'
      | 'deceased'
      | 'disputeOfDebt'
      | 'federalEmergency'
      | 'identityTheft'
      | 'legalAction'
      | 'militaryDuty'
      | 'debtValidation'
      | 'oFACSanctionedIndividual'
      | 'creditBureauDispute'
    smartReviewsEnable?: boolean
  }
}

export const internalListCaseTypes = makeData<
  InternalListCaseTypesResponse,
  InternalListCaseTypesKey,
  InternalListCaseTypesVariables
>({
  method: 'get',
  name: 'internalListCaseTypes',
  summary: 'Get case types for company',
  path: '/companies/{companyId}/case-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'case-types', query],
})

export type GetCaseTypeByIdKey = ['case-types', string | undefined, unknown]

export type GetCaseTypeByIdResponse = CaseTypes.ResponseCaseType

export type GetCaseTypeByIdVariables = {
  caseTypeId: string | undefined
  body?: void
  query?: {
    version?: number
  }
}

export const getCaseTypeById = makeData<GetCaseTypeByIdResponse, GetCaseTypeByIdKey, GetCaseTypeByIdVariables>({
  method: 'get',
  name: 'getCaseTypeById',
  summary: 'Get type by ID',
  path: '/case-types/{caseTypeId}',
  queryKey: ({ query, caseTypeId }) => ['case-types', caseTypeId, query],
})

export type UpdateCaseTypeKey = ['case-types', string | undefined, unknown]

export type UpdateCaseTypeResponse = CaseTypes.ResponseCaseType

export type UpdateCaseTypeVariables = {
  caseTypeId: string | undefined
  body?: CaseTypes.RequestBodyCaseTypePut
  query?: {
    version?: number
  }
}

export const updateCaseType = makeData<UpdateCaseTypeResponse, UpdateCaseTypeKey, UpdateCaseTypeVariables>({
  method: 'put',
  name: 'updateCaseType',
  summary: 'Update type',
  path: '/case-types/{caseTypeId}',
  queryKey: ({ query, caseTypeId }) => ['case-types', caseTypeId, query],
})

export type CaseTypeAddAssociationKey = ['case-types', string | undefined, 'associations', unknown]

export type CaseTypeAddAssociationResponse = void

export type CaseTypeAddAssociationVariables = {
  caseTypeId: string | undefined
  body?: void
  query?: {
    objectType: 'doNotInteractType' | 'notificationType' | 'loanType'
    objectId: number
  }
}

export const caseTypeAddAssociation = makeData<
  CaseTypeAddAssociationResponse,
  CaseTypeAddAssociationKey,
  CaseTypeAddAssociationVariables
>({
  method: 'post',
  name: 'caseTypeAddAssociation',
  summary: 'Add association',
  path: '/case-types/{caseTypeId}/associations',
  queryKey: ({ query, caseTypeId }) => ['case-types', caseTypeId, 'associations', query],
})

export type CaseTypeRemoveAssociationKey = ['case-types', string | undefined, 'associations', unknown]

export type CaseTypeRemoveAssociationResponse = void

export type CaseTypeRemoveAssociationVariables = {
  caseTypeId: string | undefined
  body?: void
  query?: {
    objectType: 'doNotInteractType' | 'notificationType' | 'loanType'
    objectId: number
  }
}

export const caseTypeRemoveAssociation = makeData<
  CaseTypeRemoveAssociationResponse,
  CaseTypeRemoveAssociationKey,
  CaseTypeRemoveAssociationVariables
>({
  method: 'delete',
  name: 'caseTypeRemoveAssociation',
  summary: 'Remove association',
  path: '/case-types/{caseTypeId}/associations',
  queryKey: ({ query, caseTypeId }) => ['case-types', caseTypeId, 'associations', query],
})

export type CreateSmartReviewTasksKey = ['case-types', string | undefined, 'create-smart-review-tasks', unknown]

export type CreateSmartReviewTasksResponse = void

export type CreateSmartReviewTasksVariables = {
  caseTypeId: string | undefined
  body?: void
  query?: {
    asOfDate?: string
  }
}

export const createSmartReviewTasks = makeData<
  CreateSmartReviewTasksResponse,
  CreateSmartReviewTasksKey,
  CreateSmartReviewTasksVariables
>({
  method: 'post',
  name: 'createSmartReviewTasks',
  summary: 'Create case type review tasks for ID',
  path: '/case-types/{caseTypeId}/create-smart-review-tasks',
  queryKey: ({ query, caseTypeId }) => ['case-types', caseTypeId, 'create-smart-review-tasks', query],
})

export type InternalCreateCaseTypeSmartReviewTasksKey = [
  'companies',
  string | undefined,
  'case-types',
  string | undefined,
  'create-smart-review-tasks',
  unknown,
]

export type InternalCreateCaseTypeSmartReviewTasksResponse = void

export type InternalCreateCaseTypeSmartReviewTasksVariables = {
  companyId: string | undefined
  caseTypeId: string | undefined
  body?: void
  query?: {
    asOfDate?: string
  }
}

export const internalCreateCaseTypeSmartReviewTasks = makeData<
  InternalCreateCaseTypeSmartReviewTasksResponse,
  InternalCreateCaseTypeSmartReviewTasksKey,
  InternalCreateCaseTypeSmartReviewTasksVariables
>({
  method: 'post',
  name: 'internalCreateCaseTypeSmartReviewTasks',
  summary: 'Create case type review settings for ID',
  path: '/companies/{companyId}/case-types/{caseTypeId}/create-smart-review-tasks',
  queryKey: ({ query, companyId, caseTypeId }) => [
    'companies',
    companyId,
    'case-types',
    caseTypeId,
    'create-smart-review-tasks',
    query,
  ],
})

export type ListCasesByCompanyKey = ['cases', unknown]

export type ListCasesByCompanyResponse = CaseTypes.ResponseCasesPaging

export type ListCasesByCompanyVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    sortBy?: Array<
      | 'id'
      | '-id'
      | '+id'
      | 'caseType'
      | '-caseType'
      | '+caseType'
      | 'createdAt'
      | '-createdAt'
      | '+createdAt'
      | 'createdByUserFirstName'
      | '-createdByUserFirstName'
      | '+createdByUserFirstName'
      | 'createdByUserLastName'
      | '-createdByUserLastName'
      | '+createdByUserLastName'
      | 'externalId'
      | '-externalId'
      | '+externalId'
      | 'lastViewedAt'
      | '-lastViewedAt'
      | '+lastViewedAt'
      | 'lastSubresourceUpdatedAt'
      | '-lastSubresourceUpdatedAt'
      | '+lastSubresourceUpdatedAt'
      | 'name'
      | '-name'
      | '+name'
      | 'outcome'
      | '-outcome'
      | '+outcome'
      | 'status'
      | '-status'
      | '+status'
      | 'statusUpdatedAt'
      | '-statusUpdatedAt'
      | '+statusUpdatedAt'
      | 'owningEmployee.displayName'
      | '-owningEmployee.displayName'
      | '+owningEmployee.displayName'
      | 'snoozedUntil'
      | '-snoozedUntil'
      | '+snoozedUntil'
    >
    expand?: boolean
    include?: Array<string>
    associations?: 'all' | 'documents' | 'cases' | 'interactions' | 'loans'
    outcome?: 'all' | 'approved' | 'denied' | 'falsePositive' | 'noOutcome'
    status?: 'all' | 'initiated' | 'processing' | 'completed' | 'canceled' | 'reopened'
    statuses?: Array<'all' | 'initiated' | 'processing' | 'completed' | 'canceled' | 'reopened'>
    statusUpdatedBefore?: string
    statusUpdatedAfter?: string
    caseNumber?: string
    caseType?:
      | 'generic'
      | 'bankruptcy'
      | 'ceaseCommunication'
      | 'collection'
      | 'deceased'
      | 'disputeOfDebt'
      | 'federalEmergency'
      | 'identityTheft'
      | 'legalAction'
      | 'militaryDuty'
      | 'debtValidation'
      | 'oFACSanctionedIndividual'
      | 'creditBureauDispute'
    caseTypeId?: SchemaTypes.AnyId
    createdBefore?: string
    createdAfter?: string
    nupUpdatedNull?: boolean
    snoozedUntilBefore?: string
    snoozedUntilAfter?: string
    owningEmployeeId?: string
    loanTypes?: Array<'none' | 'lineOfCredit' | 'installment'>
    'interaction.status'?: Array<InteractionTypes.InteractionStatus>
    'interaction.channel'?: Array<InteractionTypes.InteractionChannel>
    'interaction.scheduledAtFromBefore'?: string
    'interaction.scheduledAtFromAfter'?: string
    'interaction.scheduledAtToBefore'?: string
    'interaction.scheduledAtToAfter'?: string
    associatedDocumentId?: string
  }
}

export const listCasesByCompany = makeData<
  ListCasesByCompanyResponse,
  ListCasesByCompanyKey,
  ListCasesByCompanyVariables
>({
  method: 'get',
  name: 'listCasesByCompany',
  summary: 'Get all cases',
  path: '/cases',
  queryKey: ({ query }) => ['cases', query],
})

export type ListCasesByBorrowerKey = ['people', string | undefined, 'cases', unknown]

export type ListCasesByBorrowerResponse = CaseTypes.ResponseCasesPaging

export type ListCasesByBorrowerVariables = {
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    sortBy?: Array<
      | 'id'
      | '-id'
      | '+id'
      | 'caseType'
      | '-caseType'
      | '+caseType'
      | 'createdAt'
      | '-createdAt'
      | '+createdAt'
      | 'createdByUserFirstName'
      | '-createdByUserFirstName'
      | '+createdByUserFirstName'
      | 'createdByUserLastName'
      | '-createdByUserLastName'
      | '+createdByUserLastName'
      | 'externalId'
      | '-externalId'
      | '+externalId'
      | 'lastViewedAt'
      | '-lastViewedAt'
      | '+lastViewedAt'
      | 'lastSubresourceUpdatedAt'
      | '-lastSubresourceUpdatedAt'
      | '+lastSubresourceUpdatedAt'
      | 'name'
      | '-name'
      | '+name'
      | 'outcome'
      | '-outcome'
      | '+outcome'
      | 'status'
      | '-status'
      | '+status'
      | 'statusUpdatedAt'
      | '-statusUpdatedAt'
      | '+statusUpdatedAt'
      | 'owningEmployee.displayName'
      | '-owningEmployee.displayName'
      | '+owningEmployee.displayName'
      | 'snoozedUntil'
      | '-snoozedUntil'
      | '+snoozedUntil'
    >
    expand?: boolean
    include?: Array<string>
    associations?: 'all' | 'documents' | 'cases' | 'interactions' | 'loans'
    outcome?: 'all' | 'approved' | 'denied' | 'falsePositive' | 'noOutcome'
    status?: 'all' | 'initiated' | 'processing' | 'completed' | 'canceled' | 'reopened'
    statuses?: Array<'all' | 'initiated' | 'processing' | 'completed' | 'canceled' | 'reopened'>
    statusUpdatedBefore?: string
    statusUpdatedAfter?: string
    caseNumber?: string
    caseType?:
      | 'generic'
      | 'bankruptcy'
      | 'ceaseCommunication'
      | 'collection'
      | 'deceased'
      | 'disputeOfDebt'
      | 'federalEmergency'
      | 'identityTheft'
      | 'legalAction'
      | 'militaryDuty'
      | 'debtValidation'
      | 'oFACSanctionedIndividual'
      | 'creditBureauDispute'
    createdBefore?: string
    createdAfter?: string
    nupUpdatedNull?: boolean
    snoozedUntilBefore?: string
    snoozedUntilAfter?: string
    owningEmployeeId?: string
    loanTypes?: Array<'none' | 'lineOfCredit' | 'installment'>
    'interaction.status'?: Array<InteractionTypes.InteractionStatus>
    'interaction.channel'?: Array<InteractionTypes.InteractionChannel>
    'interaction.scheduledAtFromBefore'?: string
    'interaction.scheduledAtFromAfter'?: string
    'interaction.scheduledAtToBefore'?: string
    'interaction.scheduledAtToAfter'?: string
    associatedDocumentId?: string
  }
}

export const listCasesByBorrower = makeData<
  ListCasesByBorrowerResponse,
  ListCasesByBorrowerKey,
  ListCasesByBorrowerVariables
>({
  method: 'get',
  name: 'listCasesByBorrower',
  summary: 'Get borrower cases',
  path: '/people/{personId}/cases',
  queryKey: ({ query, personId }) => ['people', personId, 'cases', query],
})

export type CreateCaseKey = ['people', string | undefined, 'cases', unknown]

export type CreateCaseResponse = CaseTypes.ResponseCase

export type CreateCaseVariables = {
  personId: string | undefined
  body?: CaseTypes.RequestBodyCasePost
  query?: void
}

export const createCase = makeData<CreateCaseResponse, CreateCaseKey, CreateCaseVariables>({
  method: 'post',
  name: 'createCase',
  summary: 'Create borrower case',
  path: '/people/{personId}/cases',
  queryKey: ({ query, personId }) => ['people', personId, 'cases', query],
})

export type GetCaseByIdKey = ['people', string | undefined, 'cases', string | undefined, unknown]

export type GetCaseByIdResponse = CaseTypes.ResponseCase

export type GetCaseByIdVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: void
  query?: {
    associations?: 'all' | 'documents' | 'cases' | 'interactions' | 'loans'
    include?: Array<string>
  }
}

export const getCaseById = makeData<GetCaseByIdResponse, GetCaseByIdKey, GetCaseByIdVariables>({
  method: 'get',
  name: 'getCaseById',
  summary: 'Get borrower case by ID',
  path: '/people/{personId}/cases/{caseId}',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, query],
})

export type UpdateCaseKey = ['people', string | undefined, 'cases', string | undefined, unknown]

export type UpdateCaseResponse = CaseTypes.ResponseCase

export type UpdateCaseVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyCasePut
  query?: void
}

export const updateCase = makeData<UpdateCaseResponse, UpdateCaseKey, UpdateCaseVariables>({
  method: 'put',
  name: 'updateCase',
  summary: 'Update case',
  path: '/people/{personId}/cases/{caseId}',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, query],
})

export type AddCaseAssociationKey = ['people', string | undefined, 'cases', string | undefined, unknown]

export type AddCaseAssociationResponse = void

export type AddCaseAssociationVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyAssociationPost
  query?: void
}

export const addCaseAssociation = makeData<
  AddCaseAssociationResponse,
  AddCaseAssociationKey,
  AddCaseAssociationVariables
>({
  method: 'post',
  name: 'addCaseAssociation',
  summary: 'Add association',
  path: '/people/{personId}/cases/{caseId}',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, query],
})

export type RemoveCaseAssociationKey = ['people', string | undefined, 'cases', string | undefined, unknown]

export type RemoveCaseAssociationResponse = void

export type RemoveCaseAssociationVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: void
  query?: {
    objectType: 'document' | 'case' | 'interaction' | 'loan' | 'transaction'
    objectId: string
    /**
     * @default
     *     related
     */
    relation?: 'causes' | 'blocks' | 'duplicate' | 'related'
  }
}

export const removeCaseAssociation = makeData<
  RemoveCaseAssociationResponse,
  RemoveCaseAssociationKey,
  RemoveCaseAssociationVariables
>({
  method: 'delete',
  name: 'removeCaseAssociation',
  summary: 'Remove association',
  path: '/people/{personId}/cases/{caseId}',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, query],
})

export type CaseEscalationsGetKey = ['people', string | undefined, 'cases', string | undefined, 'escalations', unknown]

export type CaseEscalationsGetResponse = CaseTypes.ResponseCaseEscalationPaging

export type CaseEscalationsGetVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: void
  query?: void
}

export const caseEscalationsGet = makeData<
  CaseEscalationsGetResponse,
  CaseEscalationsGetKey,
  CaseEscalationsGetVariables
>({
  method: 'get',
  name: 'caseEscalationsGet',
  summary: 'List case escalations',
  path: '/people/{personId}/cases/{caseId}/escalations',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, 'escalations', query],
})

export type CaseEscalationsCreateKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'escalations',
  unknown,
]

export type CaseEscalationsCreateResponse = void

export type CaseEscalationsCreateVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyCaseEscalationPost
  query?: void
}

export const caseEscalationsCreate = makeData<
  CaseEscalationsCreateResponse,
  CaseEscalationsCreateKey,
  CaseEscalationsCreateVariables
>({
  method: 'post',
  name: 'caseEscalationsCreate',
  summary: 'Escalate or de-escalate a case',
  path: '/people/{personId}/cases/{caseId}/escalations',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, 'escalations', query],
})

export type CaseEscalationGetByIdKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'escalations',
  string | undefined,
  unknown,
]

export type CaseEscalationGetByIdResponse = CaseTypes.ResponseCaseEscalationDetailed

export type CaseEscalationGetByIdVariables = {
  personId: string | undefined
  caseId: string | undefined
  caseEscalationId: string | undefined
  body?: void
  query?: void
}

export const caseEscalationGetById = makeData<
  CaseEscalationGetByIdResponse,
  CaseEscalationGetByIdKey,
  CaseEscalationGetByIdVariables
>({
  method: 'get',
  name: 'caseEscalationGetById',
  summary: 'List case escalation by ID',
  path: '/people/{personId}/cases/{caseId}/escalations/{caseEscalationId}',
  queryKey: ({ query, personId, caseId, caseEscalationId }) => [
    'people',
    personId,
    'cases',
    caseId,
    'escalations',
    caseEscalationId,
    query,
  ],
})

export type CaseOpenEscalationsGetByCompanyKey = ['active-escalations', unknown]

export type CaseOpenEscalationsGetByCompanyResponse = CaseTypes.ResponseCaseEscalationDetailedPaging

export type CaseOpenEscalationsGetByCompanyVariables = {
  body?: void
  query?: {
    sortBy?: Array<string>
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
  }
}

export const caseOpenEscalationsGetByCompany = makeData<
  CaseOpenEscalationsGetByCompanyResponse,
  CaseOpenEscalationsGetByCompanyKey,
  CaseOpenEscalationsGetByCompanyVariables
>({
  method: 'get',
  name: 'caseOpenEscalationsGetByCompany',
  summary: 'List active case escalations for a company',
  path: '/active-escalations',
  queryKey: ({ query }) => ['active-escalations', query],
})

export type ViewCaseKey = ['people', string | undefined, 'cases', string | undefined, 'views', unknown]

export type ViewCaseResponse = void

export type ViewCaseVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: void
  query?: void
}

export const viewCase = makeData<ViewCaseResponse, ViewCaseKey, ViewCaseVariables>({
  method: 'post',
  name: 'viewCase',
  summary: 'Mark a case as viewed',
  path: '/people/{personId}/cases/{caseId}/views',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, 'views', query],
})

export type InternalListCasesByCompanyKey = ['companies', string | undefined, 'cases', unknown]

export type InternalListCasesByCompanyResponse = CaseTypes.ResponseCasesPaging

export type InternalListCasesByCompanyVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    sortBy?: Array<
      | 'id'
      | '-id'
      | '+id'
      | 'caseType'
      | '-caseType'
      | '+caseType'
      | 'createdAt'
      | '-createdAt'
      | '+createdAt'
      | 'createdByUserFirstName'
      | '-createdByUserFirstName'
      | '+createdByUserFirstName'
      | 'createdByUserLastName'
      | '-createdByUserLastName'
      | '+createdByUserLastName'
      | 'externalId'
      | '-externalId'
      | '+externalId'
      | 'lastViewedAt'
      | '-lastViewedAt'
      | '+lastViewedAt'
      | 'lastSubresourceUpdatedAt'
      | '-lastSubresourceUpdatedAt'
      | '+lastSubresourceUpdatedAt'
      | 'name'
      | '-name'
      | '+name'
      | 'outcome'
      | '-outcome'
      | '+outcome'
      | 'status'
      | '-status'
      | '+status'
      | 'statusUpdatedAt'
      | '-statusUpdatedAt'
      | '+statusUpdatedAt'
      | 'owningEmployee.displayName'
      | '-owningEmployee.displayName'
      | '+owningEmployee.displayName'
      | 'snoozedUntil'
      | '-snoozedUntil'
      | '+snoozedUntil'
    >
    expand?: boolean
    include?: Array<string>
    associations?: 'all' | 'documents' | 'cases' | 'interactions' | 'loans'
    outcome?: 'all' | 'approved' | 'denied' | 'falsePositive' | 'noOutcome'
    status?: 'all' | 'initiated' | 'processing' | 'completed' | 'canceled' | 'reopened'
    statuses?: Array<'all' | 'initiated' | 'processing' | 'completed' | 'canceled' | 'reopened'>
    statusUpdatedBefore?: string
    statusUpdatedAfter?: string
    caseNumber?: string
    caseType?:
      | 'generic'
      | 'bankruptcy'
      | 'ceaseCommunication'
      | 'collection'
      | 'deceased'
      | 'disputeOfDebt'
      | 'federalEmergency'
      | 'identityTheft'
      | 'legalAction'
      | 'militaryDuty'
      | 'debtValidation'
      | 'oFACSanctionedIndividual'
      | 'creditBureauDispute'
    caseTypeId?: SchemaTypes.AnyId
    createdBefore?: string
    createdAfter?: string
    nupUpdatedNull?: boolean
    snoozedUntilBefore?: string
    snoozedUntilAfter?: string
    owningEmployeeId?: string
    loanTypes?: Array<'none' | 'lineOfCredit' | 'installment'>
    'interaction.status'?: Array<InteractionTypes.InteractionStatus>
    'interaction.channel'?: Array<InteractionTypes.InteractionChannel>
    'interaction.scheduledAtFromBefore'?: string
    'interaction.scheduledAtFromAfter'?: string
    'interaction.scheduledAtToBefore'?: string
    'interaction.scheduledAtToAfter'?: string
    associatedDocumentId?: string
  }
}

export const internalListCasesByCompany = makeData<
  InternalListCasesByCompanyResponse,
  InternalListCasesByCompanyKey,
  InternalListCasesByCompanyVariables
>({
  method: 'get',
  name: 'internalListCasesByCompany',
  summary: 'Get cases for a specific company',
  path: '/companies/{companyId}/cases',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'cases', query],
})

export type InternalUnboundGetCaseByIdKey = ['companies', string | undefined, 'cases', string | undefined, unknown]

export type InternalUnboundGetCaseByIdResponse = CaseTypes.ResponseCase

export type InternalUnboundGetCaseByIdVariables = {
  companyId: string | undefined
  caseId: string | undefined
  body?: void
  query?: {
    associations?: 'all' | 'documents' | 'cases' | 'interactions' | 'loans'
    include?: Array<string>
  }
}

export const internalUnboundGetCaseById = makeData<
  InternalUnboundGetCaseByIdResponse,
  InternalUnboundGetCaseByIdKey,
  InternalUnboundGetCaseByIdVariables
>({
  method: 'get',
  name: 'internalUnboundGetCaseById',
  summary: 'Get case by ID',
  path: '/companies/{companyId}/cases/{caseId}',
  queryKey: ({ query, companyId, caseId }) => ['companies', companyId, 'cases', caseId, query],
})

export type InternalUnboundCaseUpdateKey = ['companies', string | undefined, 'cases', string | undefined, unknown]

export type InternalUnboundCaseUpdateResponse = CaseTypes.ResponseCase

export type InternalUnboundCaseUpdateVariables = {
  companyId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyCasePut
  query?: void
}

export const internalUnboundCaseUpdate = makeData<
  InternalUnboundCaseUpdateResponse,
  InternalUnboundCaseUpdateKey,
  InternalUnboundCaseUpdateVariables
>({
  method: 'put',
  name: 'internalUnboundCaseUpdate',
  summary: 'Update case',
  path: '/companies/{companyId}/cases/{caseId}',
  queryKey: ({ query, companyId, caseId }) => ['companies', companyId, 'cases', caseId, query],
})

export type InternalUnboundAddCaseAssociationKey = [
  'companies',
  string | undefined,
  'cases',
  string | undefined,
  unknown,
]

export type InternalUnboundAddCaseAssociationResponse = void

export type InternalUnboundAddCaseAssociationVariables = {
  companyId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyAssociationPost
  query?: void
}

export const internalUnboundAddCaseAssociation = makeData<
  InternalUnboundAddCaseAssociationResponse,
  InternalUnboundAddCaseAssociationKey,
  InternalUnboundAddCaseAssociationVariables
>({
  method: 'post',
  name: 'internalUnboundAddCaseAssociation',
  summary: 'Add association',
  path: '/companies/{companyId}/cases/{caseId}',
  queryKey: ({ query, companyId, caseId }) => ['companies', companyId, 'cases', caseId, query],
})

export type InternalUnboundRemoveCaseAssociationKey = [
  'companies',
  string | undefined,
  'cases',
  string | undefined,
  unknown,
]

export type InternalUnboundRemoveCaseAssociationResponse = void

export type InternalUnboundRemoveCaseAssociationVariables = {
  companyId: string | undefined
  caseId: string | undefined
  body?: void
  query?: {
    objectType: 'document' | 'case' | 'interaction' | 'loan' | 'transaction'
    objectId: string
    /**
     * @default
     *     related
     */
    relation?: 'causes' | 'blocks' | 'duplicate' | 'related'
  }
}

export const internalUnboundRemoveCaseAssociation = makeData<
  InternalUnboundRemoveCaseAssociationResponse,
  InternalUnboundRemoveCaseAssociationKey,
  InternalUnboundRemoveCaseAssociationVariables
>({
  method: 'delete',
  name: 'internalUnboundRemoveCaseAssociation',
  summary: 'Remove association',
  path: '/companies/{companyId}/cases/{caseId}',
  queryKey: ({ query, companyId, caseId }) => ['companies', companyId, 'cases', caseId, query],
})

export type InternalListCasesByBorrowerKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'cases',
  unknown,
]

export type InternalListCasesByBorrowerResponse = CaseTypes.ResponseCasesPaging

export type InternalListCasesByBorrowerVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    sortBy?: Array<
      | 'id'
      | '-id'
      | '+id'
      | 'caseType'
      | '-caseType'
      | '+caseType'
      | 'createdAt'
      | '-createdAt'
      | '+createdAt'
      | 'createdByUserFirstName'
      | '-createdByUserFirstName'
      | '+createdByUserFirstName'
      | 'createdByUserLastName'
      | '-createdByUserLastName'
      | '+createdByUserLastName'
      | 'externalId'
      | '-externalId'
      | '+externalId'
      | 'lastViewedAt'
      | '-lastViewedAt'
      | '+lastViewedAt'
      | 'lastSubresourceUpdatedAt'
      | '-lastSubresourceUpdatedAt'
      | '+lastSubresourceUpdatedAt'
      | 'name'
      | '-name'
      | '+name'
      | 'outcome'
      | '-outcome'
      | '+outcome'
      | 'status'
      | '-status'
      | '+status'
      | 'statusUpdatedAt'
      | '-statusUpdatedAt'
      | '+statusUpdatedAt'
      | 'owningEmployee.displayName'
      | '-owningEmployee.displayName'
      | '+owningEmployee.displayName'
      | 'snoozedUntil'
      | '-snoozedUntil'
      | '+snoozedUntil'
    >
    expand?: boolean
    include?: Array<string>
    associations?: 'all' | 'documents' | 'cases' | 'interactions' | 'loans'
    outcome?: 'all' | 'approved' | 'denied' | 'falsePositive' | 'noOutcome'
    status?: 'all' | 'initiated' | 'processing' | 'completed' | 'canceled' | 'reopened'
    statuses?: Array<'all' | 'initiated' | 'processing' | 'completed' | 'canceled' | 'reopened'>
    statusUpdatedBefore?: string
    statusUpdatedAfter?: string
    caseNumber?: string
    caseType?:
      | 'generic'
      | 'bankruptcy'
      | 'ceaseCommunication'
      | 'collection'
      | 'deceased'
      | 'disputeOfDebt'
      | 'federalEmergency'
      | 'identityTheft'
      | 'legalAction'
      | 'militaryDuty'
      | 'debtValidation'
      | 'oFACSanctionedIndividual'
      | 'creditBureauDispute'
    caseTypeId?: SchemaTypes.AnyId
    createdBefore?: string
    createdAfter?: string
    nupUpdatedNull?: boolean
    snoozedUntilBefore?: string
    snoozedUntilAfter?: string
    owningEmployeeId?: string
    loanTypes?: Array<'none' | 'lineOfCredit' | 'installment'>
    'interaction.status'?: Array<InteractionTypes.InteractionStatus>
    'interaction.channel'?: Array<InteractionTypes.InteractionChannel>
    'interaction.scheduledAtFromBefore'?: string
    'interaction.scheduledAtFromAfter'?: string
    'interaction.scheduledAtToBefore'?: string
    'interaction.scheduledAtToAfter'?: string
    associatedDocumentId?: string
  }
}

export const internalListCasesByBorrower = makeData<
  InternalListCasesByBorrowerResponse,
  InternalListCasesByBorrowerKey,
  InternalListCasesByBorrowerVariables
>({
  method: 'get',
  name: 'internalListCasesByBorrower',
  summary: 'Get cases',
  path: '/companies/{companyId}/people/{personId}/cases',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'cases', query],
})

export type InternalCreateCaseKey = ['companies', string | undefined, 'people', string | undefined, 'cases', unknown]

export type InternalCreateCaseResponse = CaseTypes.ResponseCase

export type InternalCreateCaseVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: CaseTypes.RequestBodyCasePost
  query?: void
}

export const internalCreateCase = makeData<
  InternalCreateCaseResponse,
  InternalCreateCaseKey,
  InternalCreateCaseVariables
>({
  method: 'post',
  name: 'internalCreateCase',
  summary: 'Create case',
  path: '/companies/{companyId}/people/{personId}/cases',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'cases', query],
})

export type InternalGetCaseByIdKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'cases',
  string | undefined,
  unknown,
]

export type InternalGetCaseByIdResponse = CaseTypes.ResponseCase

export type InternalGetCaseByIdVariables = {
  companyId: string | undefined
  personId: string | undefined
  caseId: string | undefined
  body?: void
  query?: {
    associations?: 'all' | 'documents' | 'cases' | 'interactions' | 'loans'
    include?: Array<string>
  }
}

export const internalGetCaseById = makeData<
  InternalGetCaseByIdResponse,
  InternalGetCaseByIdKey,
  InternalGetCaseByIdVariables
>({
  method: 'get',
  name: 'internalGetCaseById',
  summary: 'Get borrower case by ID',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}',
  queryKey: ({ query, companyId, personId, caseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    query,
  ],
})

export type InternalUpdateCaseKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'cases',
  string | undefined,
  unknown,
]

export type InternalUpdateCaseResponse = CaseTypes.ResponseCase

export type InternalUpdateCaseVariables = {
  companyId: string | undefined
  personId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyCasePut
  query?: void
}

export const internalUpdateCase = makeData<
  InternalUpdateCaseResponse,
  InternalUpdateCaseKey,
  InternalUpdateCaseVariables
>({
  method: 'put',
  name: 'internalUpdateCase',
  summary: 'Update case',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}',
  queryKey: ({ query, companyId, personId, caseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    query,
  ],
})

export type InternalAddCaseAssociationKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'cases',
  string | undefined,
  unknown,
]

export type InternalAddCaseAssociationResponse = void

export type InternalAddCaseAssociationVariables = {
  companyId: string | undefined
  personId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyAssociationPost
  query?: void
}

export const internalAddCaseAssociation = makeData<
  InternalAddCaseAssociationResponse,
  InternalAddCaseAssociationKey,
  InternalAddCaseAssociationVariables
>({
  method: 'post',
  name: 'internalAddCaseAssociation',
  summary: 'Add association',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}',
  queryKey: ({ query, companyId, personId, caseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    query,
  ],
})

export type InternalRemoveCaseAssociationKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'cases',
  string | undefined,
  unknown,
]

export type InternalRemoveCaseAssociationResponse = void

export type InternalRemoveCaseAssociationVariables = {
  companyId: string | undefined
  personId: string | undefined
  caseId: string | undefined
  body?: void
  query?: {
    objectType: 'document' | 'case' | 'interaction' | 'loan' | 'transaction'
    objectId: string
    /**
     * @default
     *     related
     */
    relation?: 'causes' | 'blocks' | 'duplicate' | 'related'
  }
}

export const internalRemoveCaseAssociation = makeData<
  InternalRemoveCaseAssociationResponse,
  InternalRemoveCaseAssociationKey,
  InternalRemoveCaseAssociationVariables
>({
  method: 'delete',
  name: 'internalRemoveCaseAssociation',
  summary: 'Remove association',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}',
  queryKey: ({ query, companyId, personId, caseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    query,
  ],
})

export type InternalGetCaseEscalationKey = [
  'companies',
  string | undefined,
  'cases',
  string | undefined,
  'escalations',
  unknown,
]

export type InternalGetCaseEscalationResponse = CaseTypes.ResponseCaseEscalationPaging

export type InternalGetCaseEscalationVariables = {
  companyId: string | undefined
  caseId: string | undefined
  body?: void
  query?: void
}

export const internalGetCaseEscalation = makeData<
  InternalGetCaseEscalationResponse,
  InternalGetCaseEscalationKey,
  InternalGetCaseEscalationVariables
>({
  method: 'get',
  name: 'internalGetCaseEscalation',
  summary: 'List case escalations',
  path: '/companies/{companyId}/cases/{caseId}/escalations',
  queryKey: ({ query, companyId, caseId }) => ['companies', companyId, 'cases', caseId, 'escalations', query],
})

export type InternalCreateCaseEscalationKey = [
  'companies',
  string | undefined,
  'cases',
  string | undefined,
  'escalations',
  unknown,
]

export type InternalCreateCaseEscalationResponse = void

export type InternalCreateCaseEscalationVariables = {
  companyId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyCaseEscalationPost
  query?: void
}

export const internalCreateCaseEscalation = makeData<
  InternalCreateCaseEscalationResponse,
  InternalCreateCaseEscalationKey,
  InternalCreateCaseEscalationVariables
>({
  method: 'post',
  name: 'internalCreateCaseEscalation',
  summary: 'Escalate or de-escalate a case',
  path: '/companies/{companyId}/cases/{caseId}/escalations',
  queryKey: ({ query, companyId, caseId }) => ['companies', companyId, 'cases', caseId, 'escalations', query],
})

export type InternalCaseOpenEscalationsGetByCompanyKey = [
  'companies',
  string | undefined,
  'active-escalations',
  unknown,
]

export type InternalCaseOpenEscalationsGetByCompanyResponse = CaseTypes.ResponseCaseEscalationDetailedPaging

export type InternalCaseOpenEscalationsGetByCompanyVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    sortBy?: Array<string>
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
  }
}

export const internalCaseOpenEscalationsGetByCompany = makeData<
  InternalCaseOpenEscalationsGetByCompanyResponse,
  InternalCaseOpenEscalationsGetByCompanyKey,
  InternalCaseOpenEscalationsGetByCompanyVariables
>({
  method: 'get',
  name: 'internalCaseOpenEscalationsGetByCompany',
  summary: 'List active case escalations for a company',
  path: '/companies/{companyId}/active-escalations',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'active-escalations', query],
})

export type CreateTaskFromNoteKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'create-task-from-note',
  unknown,
]

export type CreateTaskFromNoteResponse = void

export type CreateTaskFromNoteVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: {
    /** ID of the note to use as the source of the task. */
    noteId?: string
  }
  query?: void
}

export const createTaskFromNote = makeData<
  CreateTaskFromNoteResponse,
  CreateTaskFromNoteKey,
  CreateTaskFromNoteVariables
>({
  method: 'post',
  name: 'createTaskFromNote',
  summary: 'Create task from case note',
  path: '/people/{personId}/cases/{caseId}/create-task-from-note',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, 'create-task-from-note', query],
})
